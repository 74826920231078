import React, { useState, useEffect, useCallback, useContext } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import EditLocationModal from "./EditLocationModal";
import { updateLocation, useLocationsByGroupId, useLocationsTypeProduction } from "../util/db";
import Paper from "@material-ui/core/Paper";
import LocationListItem from "./LocationListItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MapIcon from '@material-ui/icons/Map';
import CreateMapDialog from "./PDFMaps/CreateMapDialog";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DateContext } from '../providers/DateProvider';
import { CategoryContext } from '../providers/CategoryProvider';
import {DndContext} from '@dnd-kit/core';
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";


// import MapDirections from "./MapDirections";

function LocationList(props) {
            
    const {
        data: locations,
        status: locationsStatus,
        error: locationsError,
    } = useLocationsByGroupId(props.groupId);

    const {
      data: productionLocations,
      status: productionLocationsStatus,
      error: productionLocationsError,
  } = useLocationsTypeProduction(props.productionId);

    const [creatingLocation, setCreatingLocation] = useState(false);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const locationsAreEmpty = !locations || locations.length === 0;
    const productionLocationsAreEmpty = !productionLocations || productionLocations.length === 0;
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [createMapDialogOpened, setCreateMapDialogOpened] = useState(false);
    const { day, dayStamp } = useContext(DateContext);
    const { category, setCategory } = useContext(CategoryContext);
    const filteredFromLocations = productionLocations?.filter(function(loc, i) {
      return ((loc["type"]?.toLowerCase() === "production office" || loc["type"]?.toLowerCase() === "crew hotel"));
    })

    const filterLocations = useCallback(() => {
      let filty = '';
      filty = locations?.filter(loc => loc.category.includes(category))
      setFilteredLocations(filty);
  }, [category, locations])

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const handleOnDragEnd = (event) => {
    const { active, over} = event;
    console.log({active, over})
    if (over && active.id !== over.id){
      setFilteredLocations(filteredLocations => {
        const oldIndex = filteredLocations.findIndex(loc => loc.id === active.id);
        const newIndex = filteredLocations.findIndex(loc => loc.id === over.id);
        // console.log('updating ' + active.id + ' to ' + newIndex);

        // here, loop through each location and update the sort order to it's current position in the array!
        const newSortFilteredLocs = arrayMove(filteredLocations, oldIndex, newIndex);
        newSortFilteredLocs.forEach((loc, index) => {
          console.log(loc.id + ' at position ' + index)
          updateLocation(loc.id, {typeOrder: index});
          if (index === filteredLocations.length - 1) {
            setFilteredLocations(newSortFilteredLocs)
          }
        });
      });
    }
  }
    
    useEffect(() => {
        filterLocations();
        props.setLocs(locations);
    }, [day, filterLocations, locations, props]);

    // write db method to get production locations.
    // once we have tos and froms, pass them to create map component.
    // only show create map button if we have tos and froms.

  return (
    <>          
    <Paper style={{backgroundColor: theme.backgroundColor}}>

    {locationsError && (
      <Box mb={3}>
        <Alert severity="error">{locationsError.message}</Alert>
      </Box>
    )}
        
        {(locationsStatus === "loading" || locationsAreEmpty) && (
          <Box py={2} px={3} align="center">
            {locationsStatus === "loading" && <CircularProgress size={32} />}

            {locationsStatus !== "loading" && locationsAreEmpty && (
              <>No locations added yet. Click the button to add a location.</>
            )}
          </Box>
        )}

        {(!filteredLocations?.length && locationsStatus !== "loading" && !locationsAreEmpty) && (
          <Box py={2} px={3} align="center">
              <>No locations of this type have been added for this day.</>
          </Box>
        )}

        {locationsStatus !== "loading" && locations && (locations.length > 0) && filteredLocations && 
            <List disablePadding={true}>
              <DndContext 
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={handleOnDragEnd}
              >
                <SortableContext items={filteredLocations}>
                  {filteredLocations?.sort((a,b) => a?.typeOrder - b?.typeOrder)
                  .map((location, index) => (
                    <LocationListItem numberOfLocationsInList={filteredLocations?.length} location={location} key={index} locationLength={locations.length} index={index} groupColor={props.groupColor} groupId={props.groupId} groupCustomTypes={props.groupCustomTypes} day={day} dayStamp={dayStamp} listOfGroupsAndDays={props.listOfGroupsAndDays}/>
                  ))}
              </SortableContext>
            </DndContext>
          </List>}


      {props.lostCallNumber && <ListItem style={{borderTop:'1px solid rgba(255, 255, 255, 0.12)', textAlign: 'center', color: props.groupColor, borderBottom: '1px solid rgba(255, 255, 255, 0.12)', padding: 0, margin: 0}}>
        <ListItemText>
        <h4>LOST? <span style={{color: 'white'}}>Call {props.lostCallName ? props.lostCallName : '' } </span> <a href="tel:formatPhoneNumber(props.lostCallNumber" id="callNumber" >{formatPhoneNumber(props.lostCallNumber)}</a></h4>
        </ListItemText>
        </ListItem>}

      {creatingLocation && <EditLocationModal productionId={props.productionId} onDone={() => setCreatingLocation(false)} startDate={dayStamp} endDate={dayStamp} groupId={props.groupId} groupCustomTypes={props.groupCustomTypes}/>}
        <Box padding={2} style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row'}}
            >
            <Button
            style={{backgroundColor: props.groupColor}}
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => setCreatingLocation(true)}
            >
                + Location
            </Button>
            {(productionLocationsStatus !== "loading" 
            && !productionLocationsAreEmpty 
            && productionLocations?.length > 0 
            && locations?.length > 0 )
            && (productionLocations.some(loc => loc.type.toLowerCase() === 'production office') || productionLocations.some(loc => loc.type.toLowerCase() === 'crew hotel'))            
            && category !== 'production'
            && !isSmall
            && <Button
            style={{  display:'flex', marginLeft: 'auto', backgroundColor: props.groupColor}}
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => setCreateMapDialogOpened(true)}
            >
                <MapIcon style={{paddingRight: 5}} /> Create Map
            </Button>}
            </Box>
        </Paper>
      <CreateMapDialog createMapDialogOpened={createMapDialogOpened} setCreateMapDialogOpened={setCreateMapDialogOpened} fromLocations={filteredFromLocations} destinationLocations={locations} group={props.groupName} date={day} allGroupLocations={filteredLocations} lostCall={props.lostCallNumber} lostCallName={props.lostCallName} specialInstructions={null} formatPhoneNumber={formatPhoneNumber} />
    </>
  );
}

export default LocationList;
