import React, {useState, useContext} from "react";
import { SearchContext } from "../providers/SearchProvider";
import { DateContext } from "../providers/DateProvider";
import { CategoryContext } from "../providers/CategoryProvider";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

function SearchedLocationListItem(props) {
  
  const [hover, setHover] = useState(false);
  const { setSearchFocused, setSearchText, setPreventBlur} = useContext(SearchContext);
  const { setDay, setDayStamp } = useContext(DateContext);
  const { setCategory } = useContext(CategoryContext);

  const handleMouseEnter = () => {
    setPreventBlur(true);
    setHover(true);
  }

  const handleMouseLeave = () => {
    setHover(false);
  }

  const formatDate = (theDate) => {
  const newDate = new Date(theDate);
  const day = String(newDate.getDate()).padStart(2, '0'); // Get day and add leading zero if needed
  const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and add leading zero
  const year = String(newDate.getFullYear()); // Get year

  const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  const flexRow = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid gray',
    padding: 8,
    backgroundColor: hover ? 'rgba(0, 0, 0, 0.3)' : 'transparent', // Change background on hover
  }

  const flexCol = {
    marginRight: 10,
    fontSize: props.isSmall ? 10 : 'small'
  }

  const rowMobile = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottom: '1px solid gray',
    padding: 8,
    backgroundColor: hover ? 'rgba(0, 0, 0, 0.3)' : 'transparent', // Change background on hover
  }
  const handleOnClick = (e) => {
    var dateForDateContextFormat = new Date(props.location?.startDate ? props.location.startDate : props.location?.day);
    setDay(dateForDateContextFormat.toDateString());
    setDayStamp(dateForDateContextFormat.getTime());
    setCategory(props.location.category);
    setSearchFocused(false);
    setSearchText('')
  }

return (<>
        {!props.isXs && <li style={{...flexRow, color: 'lightGray', cursor: "pointer"}} 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOnClick}
        >
          <div style={{...flexCol, width: '200px'}}>{props.location.type} {props.location.setName ? '- ' + props.location.setName : '' } </div>
          <div style={{...flexCol, width: '200px'}}>{props.location.name}</div>
          <div style={{...flexCol, width: '200px'}}><FiberManualRecordIcon style={{fontSize: '10px', color: props.location.groupColor}} /> {props.location.groupName}</div>
          <div style={{...flexCol, width: '400px'}}>{props.location?.mapData?.address}</div>
          <div style={{...flexCol, width: '100px'}}>{props.location.category}</div>
          <div style={{...flexCol, width: '150px'}}>{props.location.startDate ? formatDate(props.location.startDate) : formatDate(props.location.day)}</div>
        </li>}
        {props.isXs && <div style={{...rowMobile, cursor: "pointer"}} 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOnClick}
        >
          <div ><span style={{color: 'white', fontSize: 11}}>NAME:</span> {props.location.name}</div>
          <div><span style={{color: 'white', fontSize: 11}}>TYPE:</span> {props.location.type} {props.location.setName ? '- ' + props.location.setName : '' } </div>
          <div ><span style={{color: 'white', fontSize: 11}}>GROUP:</span> {props.location.groupName} <FiberManualRecordIcon style={{fontSize: '10px', color: props.location.groupColor}} /></div>
          <div ><span style={{color: 'white', fontSize: 11}}>ADDRESS:</span> {props.location?.mapData?.address}</div>
          <div><span style={{color: 'white', fontSize: 11}}>CATEGORY:</span> {props.location.category}</div>
          <div><span style={{color: 'white', fontSize: 11}}>DATE:</span> {props.location.startDate ? formatDate(props.location.startDate) : formatDate(props.location.day)}</div>
        </div>}
        </>
    )
}

export default SearchedLocationListItem;
