import React, { useContext } from "react";
import DateCarousel from "./DateCarousel";
import LocationGroupList from "./LocationGroupList";
import LocationFilterBar from "./LocationFilterBar";
import { CategoryContext } from '../providers/CategoryProvider';

function DashboardLocations(props) {
  // const [locationCategory, setLocationCategory] = useState('shooting');
  const {category} = useContext(CategoryContext);

  return (
    <>              
      <DateCarousel
          size={4}
          textAlign="center"
          disabled={category === 'production'}
        />
    <LocationFilterBar /> 
    <LocationGroupList productionId={props.productionId} />
    </>
  );
}

export default DashboardLocations;
 