import React, { useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import {Box} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardLocations from "./DashboardLocations";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { SearchContext } from '../providers/SearchProvider';
import PricingSection from "./PricingSection";
import SearchedLocationList from "./SearchedLocationList";
import ExpandingSearchField from "./ExpandingSearchField";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

function DashboardSection(props) {
  const auth = useAuth();
  const router = useRouter();
  const { searchFocused, searchText } = useContext(SearchContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));   

  return (
    <Section
    style={{ paddingTop: "30px" }}
    bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >

      <Container>
        {auth.user.planIsActive && <ExpandingSearchField />}
        <SectionHeader
          style={{minHeight: '50px'}}
          title={ searchFocused && isSmall ? '' : props.title}
          // subtitle={props.company}
          size={!isSmall ? 3 : 4}
          textAlign="center"
        />
        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
      {auth.user.planIsActive ? 
        <Grid container={true} spacing={10}>
          <Grid item={true} xs={12}>
            {!searchFocused && <DashboardLocations productionId={props.productionId}/>}
            {searchFocused && <SearchedLocationList productionId={props.productionId} searchText={searchText} />}
          </Grid>
        </Grid> : <>
       <Typography align="center">Subscribe to get access to the locations dashboard. Try free for 14 days. Cancel anytime</Typography>
        <PricingSection />
        </>}
      </Container>
    </Section>
  );
}

export default DashboardSection;
