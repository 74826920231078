import React, { useState, Fragment,forwardRef, useContext, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import LocationList from "./LocationList";
import Circle from '@uiw/react-color-circle';
import Popover from '@material-ui/core/Popover';
import Button from "@material-ui/core/Button";
import { deleteLocationGroup, deleteLocationsByGroup, updateLocationGroup } from "../util/db";
import {Collapse} from 'react-collapse';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import { copyLocationGroup, deletePhoneFromLocationGroup } from "../util/db";
import { DateContext } from '../providers/DateProvider';
import { CategoryContext } from '../providers/CategoryProvider';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { toast } from 'react-hot-toast';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  groupContainer: {
    marginBottom: -10
  },
  groupBoxHeader: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    fontWeight: 800,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
},
    groupHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 16,
        fontWeight: 800
    }
}));

const DatePickerCopyGroupWrapper = styled.section`
  .react-datepicker {
    background-color: #151824ff;
    border-radius: 5px;
    padding: 30px 10px 0px 10px;
    border-color: #151824ff;
    min-height: 465px;
    }
    .react-datepicker__month-container {
    background-color: #151824ff;
    min-height: 465px;
    }
    .react-datepicker__day {
    color: white;
    line-height: 2.5rem;
    }
    .react-datepicker__day:hover {
    background-color: #0059a6;
    }
    .react-datepicker__day--keyboard-selected {
    background: none;
    }
  
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
    background-color: #e91e63;
  
    }
    .react-datepicker__header {
    background-color: #151824ff;
    border-bottom: 1px solid #0059a6;
    }
    .react-datepicker__current-month {
    color: white;
    }
    .react-datepicker__day-name {
    color: white
    }
    .react-datepicker__today-button {
    background-color: #0059a6;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    position: absolute;
    bottom: 10px;
    width: 95%;
    }
    .react-datepicker__day--selected:hover {
      background-color: #e91e63;
    }
    .react-datepicker__navigation {
    top: 40px;
    }
    .react-datepicker__header__dropdown {
    padding-top: 10px
    }
    .react-datepicker__month-select {
      color: white;
      background-color: #151824ff;
      border: none;
    }
    .react-datepicker__year-select {
      color: white;
      background-color: #151824ff;
      border: none;
    }
    .react-datepicker__day--outside-month {
        /* visibility: hidden; */
        opacity: 0.2;
        pointer-events: none; /* to prevent click */
    }
`;

const DatePickerGroupWrapper = styled.section`
  .react-datepicker {
    /* border: none */
    background-color: #151824ff;
    border-radius: 5px;
    padding: 10px;
    border-color: #151824ff;
    }
    .react-datepicker__month-container {
    background-color: #151824ff;
    }
    .react-datepicker__day {
    color: white;
    }
    .react-datepicker__day:hover {
    background-color: #0059a6;
    }
    .react-datepicker__day--keyboard-selected {
    background: none;
    }
  
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
    background-color: #e91e63;
  
    }
    .react-datepicker__header {
    background-color: #151824ff;
    border-bottom: 1px solid #0059a6;
    }
    .react-datepicker__current-month {
    color: white;
    }
    .react-datepicker__day-name {
    color: white
    }
    .react-datepicker__today-button {
    background-color: #0059a6;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    position: absolute;
    bottom: 10px;
    width: 95%;
    }
    .react-datepicker__day--selected:hover {
      background-color: #e91e63;
    }
    .react-datepicker__navigation {
    top: 14px;
    }
    .react-datepicker__header__dropdown {
    padding-top: 10px
    }
    .react-datepicker__month-select {
      color: white;
      background-color: #151824ff;
      border: none;
    }
    .react-datepicker__year-select {
      color: white;
      background-color: #151824ff;
      border: none;
    }
    .react-datepicker__day--outside-month {
      /* visibility: hidden; */
      opacity: 0.2;
      pointer-events: none; /* to prevent click */
    }
`;

const todaysDate = new Date().toDateString();

function LocationGroupListItem(props) {

  const classes = useStyles();
  // const [hsva, setHsva] = useState(hexToHsva(props.groupColor));
  const [groupHex, setGroupHex] = useState(props.groupColor);
  const [anchorElColor, setAnchorElColor] = useState(null);
  const [anchorElDateRange, setAnchorElDateRange] = useState(null);
  const [colorOpen, setColorOpen] = useState(false);
  const colorId = colorOpen ? 'color-popover' : undefined;
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [opened, setOpened] = useState(true);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmCopyOpen, setConfirmCopyOpen] = useState(false);
  const [editGroupNameOpen, setEditGroupNameOpen] = useState(false);
  const [addLostCallNumberOpen, setAddLostCallNumberOpen] = useState(false);
  const [lostCallNumber, setLostCallNumber] = useState(props.lostCallNumber);
  const [lostCallName, setLostCallName] = useState(props.lostCallName);
  const [pending, setPending] = useState(false);
  const theme = useTheme();
  const ifSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const [copyDay, setCopyDay] = useState(null)
  const [locs, setLocs] = useState(null);
  const {day, setDay, dayStamp, setDayStamp} = useContext(DateContext);
  const {category, setCategory} = useContext(CategoryContext);
  let startDate = new Date(props.startDate);
  let endDate = new Date(props.endDate);
  let currentSelectedDay = new Date(day);
  let origDay = new Date(props.startDate);
  const [newStartDate, setNewStartDate] = useState(new Date(props.startDate));
  const [newEndDate, setNewEndDate] = useState(new Date(props.endDate));
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const dateRangePickerId = showDateRangePicker ? 'dateRangePicker-popover' : undefined;

  const startYyyy = ifSmall ? startDate.getFullYear().toString().slice(-2) : startDate.getFullYear();
  let startMm = startDate.getMonth() + 1; // Months start at 0!
  let startDd = startDate.getDate();
  if (startDd < 10) startDd = '0' + startDd;
  if (startMm < 10) startMm = '0' + startMm;
  let formattedStartDate = startMm + '/' + startDd + (ifSmall ? '' : '/' + startYyyy);

  const endYyyy = ifSmall ? endDate.getFullYear().toString().slice(-2) : endDate.getFullYear();
  let endMm = endDate.getMonth() + 1; // Months start at 0!
  let endDd = endDate.getDate();
  if (endDd < 10) endDd = '0' + endDd;
  if (endMm < 10) endMm = '0' + endMm;
  let formattedEndDate = endMm + '/' + endDd + (ifSmall ? '' : '/' + endYyyy);

  const todayYyyy = ifSmall ? currentSelectedDay.getFullYear().toString().slice(-2) : currentSelectedDay.getFullYear();
  let todayMm = currentSelectedDay.getMonth() + 1; // Months start at 0!
  let todayDd = currentSelectedDay.getDate();
  if (todayDd < 10) todayDd = '0' + todayDd;
  if (todayMm < 10) todayMm = '0' + todayMm;
  let formattedToday = todayMm + '/' + todayDd + (ifSmall ? '' : '/' + todayYyyy);

  const origDayYyyy = ifSmall ? origDay.getFullYear.toString().slice(-2) : origDay.getFullYear();
  let origDayMm = origDay.getMonth() + 1; // Months start at 0!
  let origDayDd = origDay.getDate();
  if (origDayDd < 10) origDayDd = '0' + origDayDd;
  if (origDayMm < 10) origDayMm = '0' + origDayMm;
  let formattedOrigDay = origDayMm + '/' + origDayDd + (ifSmall ? '' : '/' + origDayYyyy);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    
  } = useSortable({id: props.groupId,
    animateLayoutChanges: () => false

  });

  const sortableStyle = {
    transform: CSS.Translate.toString(transform),
    transition
  }

  const { handleSubmit, register, errors } = useForm();
  
  const handleClickColor = (event) => {
    // setHsva(hexToHsva(props.groupColor));
    setGroupHex(props.groupColor);
    setAnchorElColor(event.currentTarget);
    setColorOpen(true);
  };

  const handleCloseColor = () => {
    setAnchorElColor(null);
    setColorOpen(false);
  };

  const handleSaveColor = (color) => {
    updateLocationGroup(props.groupId, {groupColor: color});
    setAnchorElColor(null);
    setColorOpen(false);
  }

  const handleClickDateRange = (event) => {
    setAnchorElDateRange(event.currentTarget);
    setShowDateRangePicker(!showDateRangePicker);
  };

  const handleCloseDateRange = () => {
    setAnchorElDateRange(null);
    setShowDateRangePicker(false);
    setNewStartDate(props.startDate);
    setNewEndDate(props.endDate);
  };

  const isDate = (value) => {
    return value instanceof Date && !isNaN(value.valueOf());
  }

  const updateStartEndDates = () => {
    if (newStartDate && newEndDate) {
      updateLocationGroup(props.groupId, {startDate: isDate(newStartDate) ? newStartDate.getTime() : newStartDate, endDate: isDate(newEndDate) ? newEndDate.getTime() : newEndDate});
      handleCloseDateRange();
      console.log(newStartDate)
      console.log(newEndDate)
    } else {
      console.log('issue with start and end dates.');
    }
  };

  const collapseGroup = () => {
    setOpened(!opened);
  }

  const handleClickGroupActionMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  }

  const handleCloseGroupActionMenu = () => {
    setAnchorElMenu(null);
  }

  const handleCloseConfirm = () => {
    setConfirmDeleteOpen(false);
    setConfirmCopyOpen(false);
    handleCloseEditGroupName();
    setCopyDay(null);
  };

  const handleDeleteAndClose = () => {
    setConfirmDeleteOpen(false);
    deleteLocationGroup(props.groupId)
    deleteLocationsByGroup(props.groupId)
    handleCloseEditGroupName();
  }
    
  useEffect(() => {
    setOpened(true)
}, [day, category]);

useEffect(() => {
  setNewStartDate(props.startDate);
  setNewEndDate(props.endDate);
  // console.log('the start date for group ' + props.groupName + ' is ' + newStartDate.toDateString() + ' and the end date is ' + newEndDate.toDateString());
}, [props.endDate, props.startDate]);

useEffect(() => {
  setLostCallName(props.lostCallName);
  setLostCallNumber(props.lostCallNumber);
}, [props.lostCallName, props.lostCallNumber]);

  const handleCopy = () => {
    // let colors = [ '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b' ];
    // let randomHex = Math.floor(Math.random()*colors.length);

    const copyDaysDateStamp = new Date(copyDay);
    copyDaysDateStamp.setHours(0,0,0,0);

    let theName = day === copyDay ? props.groupName.includes('copy)') ? props.groupName : props.groupName + ' (copy)' : props.groupName;
    let data = {productionId: props.productionId, name: theName, groupColor: props.groupColor, startDate: copyDaysDateStamp.getTime(), endDate: copyDaysDateStamp.getTime(), category: category, customTypes: props.groupCustomTypes ? props.groupCustomTypes : []};

    handleCloseGroupActionMenu();
    setConfirmCopyOpen(false);
    copyLocationGroup(data, locs);
    setDay(copyDay);
    setDayStamp(copyDaysDateStamp.getTime());
  }

  const handleCopyProdCat = () => {
    // let colors = [ '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b' ];
    // let randomHex = Math.floor(Math.random()*colors.length);
    let theName = props.groupName.includes('copy)') ? props.groupName : props.groupName + ' (copy)';
    let data = {productionId: props.productionId, name: theName, groupColor: props.groupColor, category: category, customTypes: props.groupCustomTypes ? props.groupCustomTypes : []};
    copyLocationGroup(data, locs);
    setConfirmCopyOpen(false);
    handleCloseGroupActionMenu();
    setCopyDay(null);
  }
  
  const handleCloseEditGroupName = () => {
    setEditGroupNameOpen(false);
    handleCloseGroupActionMenu();
  };

  const handleCloseAddLostCallNumber = () => {
    setAddLostCallNumberOpen(false);
    handleCloseGroupActionMenu();
  };

  const handleDeleteLostCallNumber = () => {
    const query = deletePhoneFromLocationGroup(props.groupId);
    query
    .then((response) => {
      handleCloseAddLostCallNumber();
      setPending(false);
      if (response.success) {
        setLostCallName('');
        setLostCallNumber('');    
        toast.success('Successfully deleted contact info.');
      } else {
        toast.error('Failed to delete contact info. Please try again later.')
      }
    })
    .catch((error) => {
      // Hide pending indicator
      setPending(false);
      // Show error alert message
      toast.error(error.message)
    });
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setNewStartDate(start);
    setNewEndDate(end);
  };

  const handleChangeGroupName = (data) => {
    handleCloseGroupActionMenu();
    setPending(true);
    const query = (props.groupId && data.newGroupName)
      ? updateLocationGroup(props.groupId, {name: data.newGroupName.toUpperCase()}) : ''

    query
      .then((response) => {
        handleCloseEditGroupName();
        setPending(false);
        if (response.success) {
          toast.success('Group name has been successfully updated.');
        } else {
          toast.error('Failed to update group name. Please try again later.')
        }
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        toast.error(error.message)
      });
  };

  const handleAddLostCallNumber = () => {
    setPending(true);
    const query = (props.groupId && lostCallNumber && lostCallName)
      ? updateLocationGroup(props.groupId, {lostCallNumber: lostCallNumber, lostCallName: lostCallName.toUpperCase()}) : ''

    query
      .then((response) => {
        handleCloseAddLostCallNumber();
        setPending(false);
        if (response.success) {
          toast.success('Contact info has been successfully updated.');
        } else {
          toast.error('Failed to update contact info. Please try again later.')
        }
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        toast.error(error.message)
      });
  };

  const CopyGroupDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
              <MenuItem >
              <ListItemIcon>
                <FileCopyIcon fontSize="medium" />
              </ListItemIcon>
                Copy Group
                </MenuItem>    </div>
));

// const oncalopen = () => {
//   console.log(newStartDate.toDateString())
// }

  return (
    <>
      {/* GROUP PANEL */}
      <div ref={setNodeRef} style={sortableStyle}>
      <Paper style={colorOpen ? {backgroundColor: groupHex} : {backgroundColor: props.groupColor}} className={classes.groupContainer}>
          <Box px={2} className={classes.groupBoxHeader}>
              <div style={{cursor: 'pointer'}} onClick={collapseGroup}>
                {opened ? <ExpandMoreIcon style={{verticalAlign: "middle"}} /> : <ExpandLessIcon style={{verticalAlign: "middle"}}  />}
               </div>
                <div>
                  <span style={{cursor: 'pointer'}} onClick={collapseGroup}>{props.groupName}</span>
                <MoreHoriz aria-haspopup="true" onClick={handleClickGroupActionMenu} style={{cursor: 'pointer', verticalAlign: 'middle', width: 40}}/>
                </div>       
              {/* <Button style={{maxHeight: 25, border: '1px solid red', padding: 0, maxWidth: '20px !important'}} size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickGroupActionMenu}> */}
              {/* </Button> */}
              <Menu
                id="simple-menu"
                anchorEl={anchorElMenu}
                keepMounted
                open={Boolean(anchorElMenu)}
                onClose={handleCloseGroupActionMenu}
              >
                <MenuItem onClick={()=>setEditGroupNameOpen(true)}>
                <ListItemIcon>
                  <EditIcon fontSize="medium" />
                </ListItemIcon>
                  Edit Group Name
                </MenuItem>

                {category !== 'production' ? <DatePickerCopyGroupWrapper>
                  <DatePicker
                  selected={new Date(day)}
                  allowSameDay
                  onChange={(date) => [setConfirmCopyOpen(true), setCopyDay(date.toDateString())]}
                  customInput={<CopyGroupDatePickerInput />}
                  todayButton="Today"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"      
                  withPortal
               >
                <div style={{position: 'absolute', top: '10px', left: '10px', color: 'white', marginLeft: 'auto !important', width: '90%'}}>SELECT A DATE TO COPY THE GROUP TO</div>
                </DatePicker>
                </DatePickerCopyGroupWrapper>
                : 
               <MenuItem onClick={()=>setConfirmCopyOpen(true)}>
               <ListItemIcon>
                <FileCopyIcon fontSize="medium" />
              </ListItemIcon>
                Copy Group
                </MenuItem> 
               }

                <MenuItem onClick={()=>setConfirmDeleteOpen(true)}>
                <ListItemIcon>
                  <DeleteIcon fontSize="medium" />
                </ListItemIcon>
                  Delete Group
                </MenuItem>
                <MenuItem onClick={()=> setAddLostCallNumberOpen(true)}>
                <ListItemIcon>
                  <AddIcCallIcon fontSize="medium" />
                </ListItemIcon>
                  Add/Edit Phone Number if Lost
                </MenuItem>
              </Menu>
              {/* <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center', width: '45%', float: 'right'}}> */}

                <div style={{display: 'flex',marginLeft: 'auto', alignItems: 'center'}}>
                {category !== 'production' && <div onClick={handleClickDateRange} style={ifSmall ? {paddingRight: '10px', cursor: 'pointer', fontSize: '.7em'} : 
                {paddingRight: '10px', cursor: 'pointer'}}>{props.startDate && props.startDate !== props.endDate ? formattedStartDate + " - " + formattedEndDate : formattedOrigDay}
                </div>}
                <div><img onClick={handleClickColor} style={{verticalAlign: 'middle', height: 24, cursor: 'pointer', float: 'right'}} src={'/colorWheelIcon.png'} alt="color wheel icon for picker" /></div>
                {props.numberOfGroupsInList > 1 && <div {...attributes} {...listeners}><DragIndicatorIcon style={{verticalAlign: "middle", cursor: 'grab'}}  /></div>}
                </div>
              
                {/* DATE RANGE PICKER POPOVER */}
                <Popover
                    id={dateRangePickerId + '-' + props.groupId}
                    open={showDateRangePicker}
                    anchorEl={anchorElDateRange}
                    onClose={handleCloseDateRange}
                    disableAutoFocus={true} // Prevent auto-focusing inside the Popover
                    disableEnforceFocus={true} // Disable strict focus trapping            
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Fragment>
                    <div>
                      <DatePickerGroupWrapper>
                      <DatePicker
                              selected={newStartDate}
                              onChange={onChange}
                              startDate={newStartDate}
                              endDate={newEndDate}
                              selectsRange
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"                  
                              inline
                              disabledKeyboardNavigation={null}
                              minDate={null}            
                            />
                      </DatePickerGroupWrapper>
                    </div>
                    <Button variant="contained" fullWidth color="primary" onClick={()=>updateStartEndDates()} disabled={(!newStartDate || !newEndDate) || (newStartDate === props.startDate && newEndDate === props.endDate)}>
                      Save
                    </Button>
                    </Fragment>
                  </Popover>
          </Box>
          <Collapse isOpened={opened}>
          <LocationList groupId={props.groupId} groupName={props.groupName} groupColor={props.groupColor} lostCallNumber={props.lostCallNumber} lostCallName={props.lostCallName}  groupCustomTypes={props.groupCustomTypes} productionId={props.productionId} setLocs={setLocs} listOfGroupsAndDays={props.listOfGroupsAndDays} />
          </Collapse>
      </Paper>
      </div>

      {/* COLOR WHEEL POPOVER */}
     <Popover
        id={colorId}
        open={colorOpen}
        anchorEl={anchorElColor}
        onClose={handleCloseColor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Fragment>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '280px'}}>
        <div style={{paddingLeft: 26, paddingTop: 10}}>
        <Circle
        colors={[ '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b' ]}
        color={groupHex} 
        // onChange={(color) => setHsva({ ...hsva, ...color.hsva })}
        onChange={(color) => {
          setGroupHex(color.hex);
        }}
      /></div>
        {/* <ShadeSlider
          hsva={hsva}
          style={{ width: 210, marginTop: 20}}
          onChange={(newShade) => {
            setHsva({ ...hsva, ...newShade });
          }}
        /> */}
        </div>

        <Button variant="contained" fullWidth color="primary" onClick={()=>handleSaveColor(groupHex)}>
          Save
        </Button>
        </Fragment>
      </Popover>

      {/* DELETE GROUP DIALOG */}
      <Dialog
            open={confirmDeleteOpen}
            onClose={handleCloseConfirm}
            style={{textAlign: 'center'}}
            aria-labelledby="responsive-dialog-title"
        >
        <DialogTitle id="responsive-dialog-title">Are you sure you want to delete "{props.groupName}"?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>THIS WILL DELETE ALL LOCATIONS IN THE GROUP.</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAndClose} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* COPY GROUP DIALOG */}
      <Dialog
            open={confirmCopyOpen}
            onClose={handleCloseConfirm}
            style={{textAlign: 'center'}}
            aria-labelledby="responsive-dialog-title"
        >
        {category !== 'production' ? <DialogTitle id="responsive-dialog-title">Are you sure you want to copy "{props.groupName}" to {copyDay == todaysDate ? "today" : copyDay}?</DialogTitle> 
        : <DialogTitle>Are you sure you want to copy "{props.groupName}" ?</DialogTitle>}
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={category === 'production' ? handleCopyProdCat : handleCopy} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* CHANGE GROUP NAME DIALOG */}
      <Dialog
            open={editGroupNameOpen}
            onClose={handleCloseEditGroupName}
        >
        <form onSubmit={handleSubmit(handleChangeGroupName)}>
        <DialogTitle>Edit Group Name:</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Grid item={true}>
          <TextField
                autoComplete='off'
                variant="outlined"
                type="text"
                label="Group Name"
                name="newGroupName"
                defaultValue={props.groupName}
                error={errors.newGroupName ? true : false}
                helperText={errors.newGroupName && errors.newGroupName.message}
                fullWidth={true}
                autoFocus={true}
                inputProps={{ style: { textTransform: "uppercase" } }}
                inputRef={register({
                  required: "Group name cannot be empty",
                })}
              />
              </Grid>
         </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditGroupName} color="primary">
            Cancel
          </Button>
          <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span>Save</span>}
                {pending && <CircularProgress size={28} />}
              </Button>
        </DialogActions>
        </form>
      </Dialog>

      {/* ADD LOST CALL NUMBER DIALOG */}
      <Dialog
            open={addLostCallNumberOpen}
            onClose={handleCloseAddLostCallNumber}
        >
        <form onSubmit={handleSubmit(handleAddLostCallNumber)}>
        <DialogTitle>Add/Edit Contact Number if Lost:</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Grid item={true}>
          <TextField
                style={{paddingBottom: '10px'}}
                autoComplete='off'
                variant="outlined"
                type="text"
                label="Name"
                name="lostCallName"
                defaultValue={props.lostCallName}
                fullWidth={true}
                autoFocus={true}
                inputProps={{ autofocus: true, style: { textTransform: "uppercase" } }}
                onChange={(e) => setLostCallName(e.target?.value)}
                />
          <PhoneInput 
          country='us'
          onlyCountries={['us']}
          label="Contact Number"
            name="lostCallNumber"
            value={props.lostCallNumber}
            onChange={phone => setLostCallNumber(phone)}
          />
              </Grid>
         </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddLostCallNumber} color="primary">
            Cancel
          </Button>
          {props.lostCallNumber && <Button 
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleDeleteLostCallNumber} >
            Delete
          </Button>}
          <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending || !lostCallNumber || isNaN(lostCallNumber) || lostCallNumber.toString().length < 11 || !lostCallName || (lostCallNumber === props.lostCallNumber && lostCallName === props.lostCallName)}
              >
                {!pending && <span>Save</span>}
                {pending && <CircularProgress size={28} />}
              </Button>
        </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default LocationGroupListItem;
