import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  writeBatch,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  getDocs,
  FieldValue,
  arrayUnion,
  arrayRemove,
  arrayContains,
  deleteField,
} from "firebase/firestore";
import { firebaseApp } from "./firebase";

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient(({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
}));

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}


/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** PRODUCTIONS ****/
/* Example query functions (modify to your needs) */

// Subscribe to production data
export function useProduction(id) {
  return useQuery(
    ["production", { id }],
    createQuery(() => doc(db, "productions", id)),
    { enabled: !!id }
  );
}

// Fetch production data once
export function useProductionOnce(id) {
  return useQuery(
    ["production", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "productions", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all productions by owner
export function useProductionsByOwner(uid) {
  return useQuery(
    ["productions", { uid }],
    createQuery(() =>
      query(
        collection(db, "productions"),
        where("uid", "==", uid),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!uid }
  );
}

// Create a new production
export async function createProduction(uid, data) {
  const newProductionRef = await addDoc(collection(db, "productions"),
  {
    uid: uid,
    ...data,
    createdAt: serverTimestamp(),
  });
  console.log(newProductionRef.id);
  // now create that first initial locations group with prod id
  // createLocationGroup({productionId: newProductionRef.id, name: "Location Group 1"});  
  return newProductionRef;
}

export async function checkIfProductionNameExists(name) {
  const collectionRef = collection(db , "productions")
  // check if production name already exists in the db
  const qSnapProductionNameExists = await getDocs(query(collectionRef, where("productionName", '==', name)));
  if (qSnapProductionNameExists.size) {
      return true;
  } else {
    return false;
  } 
}

// Update a production
export function updateProduction(id, data) {
  return updateDoc(doc(db, "productions", id), data);
}

// Delete a production
export function deleteProduction(id) {
  return deleteDoc(doc(db, "productions", id));
}

// Create a new user key
export function createUserKeys(uid, data) {
  return addDoc(collection(db, "userKeys"),
  {
    uid: uid,
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update a production key
export function updateUserKeys(id, data) {
  return updateDoc(doc(db, "userKeys", id), data);
}


/**** LOCATION CATEGORIES ****/
/* Example query functions (modify to your needs) */

// Subscribe to location data
export function useLocation(id) {
  return useQuery(
    ["location", { id }],
    createQuery(() => doc(db, "locations", id)),
    { enabled: !!id }
  );
}

// Fetch location data once
export function useLocationOnce(id) {
  return useQuery(
    ["location", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "locations", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all locations by production
export function useLocationsByProductionID(productionID) {
  return useQuery(
    ["locations", { productionID }],
    createQuery(() =>
      query(
        collection(db, "locations"),
        where("productionID", "==", productionID),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!productionID }
  );
}

// Get all locations by production ID once
export async function getLocationsByProductionIdOnce(productionID) {
  const collectionRef = collection(db , "locations")
  const queryLocations = await getDocs(query(collectionRef, where("productionID", '==', productionID), orderBy("createdAt", "desc")));
  const fetchedLocations = queryLocations.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  return fetchedLocations
  } 

// Get all groups by production ID once
export async function getGroupsByProductionIdOnce(productionID) {
  const collectionRef = collection(db , "locationGroups")
  const queryGroups = await getDocs(query(collectionRef, where("productionId", '==', productionID), orderBy("createdAt", "desc")));
  const fetchedGroups = queryGroups.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  return fetchedGroups
  } 

// Subscribe to all locations by owner
export function useLocationsTypeProduction(productionID) {
  return useQuery(
    ["locations", { productionID }],
    createQuery(() =>
      query(
        collection(db, "locations"),
        where("productionID", "==", productionID),
        where("category", "==", 'production'),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!productionID }
  );
}

// Subscribe to all locations by owner
export function useLocationsByGroupId(groupId) {
  return useQuery(
    ["locations", { groupId }],
    createQuery(() =>
      query(
        collection(db, "locations"),
        where("groupId", "==", groupId),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!groupId }
  );
}

// Create a new location
export function createLocation(data) {
  return addDoc(collection(db, "locations"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Create a new location from copy
export function createLocationFromCopy(data, timeStamp) {
  return addDoc(collection(db, "locations"), {
    ...data,
    createdAt: timeStamp,
  });
}

// Update a location
export function updateLocation(id, data) {
  return updateDoc(doc(db, "locations", id), data);
}

// Delete a location
export function deleteLocation(id) {
  return deleteDoc(doc(db, "locations", id));
}

// Delete all locations by group
export async function deleteLocationsByGroup(groupId) {
  const q = query(collection(db, "locations"), where("groupId", "==", groupId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    deleteDoc(doc.ref);
  });
  return
}

/**** LOCATION GROUPS ****/

// Subscribe to all location groups by production
export function useLocationGroupsByProductionID(productionId) {
  return useQuery(
    ["locationGroups", { productionId }],
    createQuery(() =>
      query(
        collection(db, "locationGroups"),
        where("productionId", "==", productionId),
        orderBy("createdAt", "asc")
      )
    ),
    { enabled: !!productionId }
  );
}

// Create a new location group
export function createLocationGroup(data) {
  return addDoc(collection(db, "locationGroups"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Copy a location group
export async function copyLocationGroup(data, locs) {
  // create new group
  addDoc(collection(db, "locationGroups"), {
    ...data,
    createdAt: serverTimestamp(),
  }).then(res => {
    //create locations in new group with new id
    var newGroupId = res?.["_key"]?.["path"]?.["segments"][1];
    locs.forEach(loc => {
      var newLocationData = {productionID: loc.productionID, category: loc.category, startDate: data.startDate, endDate: data.endDate, mapData: loc.mapData, name: loc.name, type: loc.type, typeOrder: loc.typeOrder, groupId: newGroupId}
      if (loc.setName) {
        newLocationData = {...newLocationData, setName: loc.setName}
      } else {
        newLocationData = {...newLocationData, setName: null}
      }
      createLocationFromCopy(newLocationData, loc.createdAt);
    });
  });
}


// Update a location group
export async function updateLocationGroup(id, data) {
  try {
    // Try updating the document
    await updateDoc(doc(db, "locationGroups", id), data);
    console.log("Location group updated successfully");
    return { success: true, message: "Location group updated successfully" };
  } catch (error) {
    // Catch any errors and handle them
    console.error("Error updating location group: ", error);
    return { success: false, message: "Failed to update location group. Please try again later." };
  }
}

// Add custom location type to a location group
export async function updateLocationGroupCustomTypes(id, data) {
  return updateDoc(doc(db, "locationGroups", id), {'customTypes': arrayUnion(data)});
// let docRef = await db.collection('locationGroups').doc(id);
// return await docRef.update({customTypes: firebase.firestore.FieldValue.arrayUnion(data)});
}

// Add custom location type to a location group
export async function deleteLocationGroupCustomType(id, data) {
  // console.log('here in the db! try to delete ' + JSON.stringify(data))
  return updateDoc(doc(db, "locationGroups", id), {'customTypes': arrayRemove(data)});
}

// Delete a location group
export function deleteLocationGroup(id) {
  return deleteDoc(doc(db, "locationGroups", id))
}

// Delete Phone Number From Location Group
export async function deletePhoneFromLocationGroup(id) {
  try {
    // Try updating the document
    await updateDoc(doc(db, "locationGroups", id), {'lostCallNumber': deleteField(), 'lostCallName': deleteField()});
    console.log("Lost contact info deleted successfully");
    return { success: true, message: "Phone contact deleted successfully" };
  } catch (error) {
    // Catch any errors and handle them
    console.error("Error deleting lost contact info: ", error);
    return { success: false, message: "Error deleting phone contact. Please try again later." };
  }
  }

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
