import React, { useEffect, useState } from 'react';
import { ReactComponent as BPinIcon } from '../../assets/mapPinB.svg';
import { ReactComponent as APinIcon } from '../../assets/mapPinA.svg';
import qrcode from '../../assets/qrimg.png';
import DOMPurify from 'dompurify';
import * as Icons from "@mui/icons-material";

<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0&icon_names=turn_slight_left" />

const PDFTurnByTurnDirections = ({ steps, toFull, fromFull, totalDuration}) => {
    const [duration, setDuration] = useState("");
    const maneuversToExclude = ['ramp', 'keep-left', 'keep-right'];

    function replaceDivsWithSpans(str, replacements) {
      // Create a regex pattern to match all keys in the replacements object
      const pattern = new RegExp(Object.keys(replacements).join('|'), 'g');
    
      // Replace each match with its corresponding value
      return str.replace(pattern, (matched) => replacements[matched]);
    }

    const DynamicIcon = ({ iconName }) => {
      const IconComponent = Icons[iconName];
      
      if (!IconComponent) {
        return <span>Icon not found</span>; // Handle invalid icon names gracefully
      }
    
      return <IconComponent />;
    };
    
    const replacements = {
      "<div style=\"font-size:0.9em\">": "<span> (",
      "</div>": ")</span>"
    };

    useEffect(()=>{
        const initialDuration = totalDuration; // Duration in seconds
        const totalDurationInMinutes = initialDuration / 60;

        // Round to the nearest whole minute
        let roundedMinutes = Math.round(totalDurationInMinutes);
        
        // If the duration is 60 minutes or more, convert to hours and minutes
        let hours = 0;
        if (roundedMinutes >= 60) {
          hours = Math.floor(roundedMinutes / 60); // Get the number of whole hours
          roundedMinutes = roundedMinutes % 60;   // Get the remaining minutes
        }
        
        // Output the result in hours and minutes
        if (hours > 0) {
          console.log(`${hours > 1 ? hours + ' hours' : hours + ' hour'} and ${roundedMinutes > 1 ? roundedMinutes + ' mins' : roundedMinutes + ' minute'}`);
          setDuration(`${hours > 1 ? hours + ' hours' : hours + ' hour'} and ${roundedMinutes > 1 ? roundedMinutes + ' mins' : roundedMinutes + ' minute'}`);
        } else if (totalDurationInMinutes > 0) {
          console.log(`${roundedMinutes > 1 ? roundedMinutes + ' mins' : roundedMinutes + ' minute'}`)
          setDuration(`${roundedMinutes > 1 ? roundedMinutes + ' mins' : roundedMinutes + ' minute'}`)
        } else {
            console.log(`${initialDuration} seconds`);
            setDuration(`${initialDuration} seconds`)
        }
      }, [totalDuration])


    return (
      <div>
        <div
          style={{
            columns:  (steps.length > 29 ? 3 : steps.length > 10 ? 2 : 1),
            columnGap: '0px',
            margin: 10,
            WebkitFontSmoothing: 'antialiased', // Camel-cased for React
            fontSmoothing: 'antialiased',      // Optional fallback
          }}
        >

            <span style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', padding: 10, border: '.5px solid gray'}}>
              <span><APinIcon width={32} height={32} style={{ fill: "red" }}/></span>
              {fromFull.mapData.address} ({fromFull.type} {fromFull.name ? ' - ' + fromFull.name : ''})
              </span>
              {steps?.length >= 1 && <div style={{ padding: 10, fontSize: 14, border: '.5px solid gray' }}>About {duration}</div>}

        {steps && steps.length > 0 ? (
          steps.map((step, index) => (
          <div
            key={index}
            style={{
              padding: '10px',
              border: '.5px solid gray',
              gap: 10,
              display: 'flex', // Ensure each item is displayed correctly
              fontSize: '12px',
            }}
          >
            {/* Displaying step instructions */}
            {step.maneuver && !maneuversToExclude.includes(step.maneuver) && 
            // <Icon name={step.maneuver.replace(/-/g, "_")} size={18} />
            <DynamicIcon iconName={step.maneuver.replace(/(?:^|-)(\w)/g, (_, char) => char.toUpperCase())} style={{fontSize: '20px'}} />
            }
            <span style={{paddingLeft: (!step.maneuver || maneuversToExclude.includes(step.maneuver)) ? '2.8em' : ''}}>{index + 1}.</span> 
            <div
            style={{ width: '100%'}}
              dangerouslySetInnerHTML={{
                __html: `${DOMPurify.sanitize(replaceDivsWithSpans(step.instructions, replacements))}`,
              }}
            />
            <span style={{marginLeft: 'auto', minWidth: '4em'}}>{step.distance}</span>
          </div>
        ))) : (<div
        style={{
          padding: '10px',
          border: '.5px solid gray',
          gap: 10,
          display: 'flex', // Ensure each item is displayed correctly
          fontSize: '12px',
        }}>Sorry, we could not get directions for this route!</div>)} 

        <div>
        <span style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', padding: 10, border: '.5px solid gray' }}>
        <span><BPinIcon width={32} height={32} style={{ fill: "red" }}/></span>
            {toFull.mapData.address} ({toFull.type} {toFull.name ? ' - ' + toFull.name : ''})
          </span>
        </div>
      </div>
      <div>
      <div style={{
          height: '120px',
          width: '100%',
          display: 'flex',
          padding: 10
          }}>
            <div style={{alignSelf: 'center'}}><h1>showNAV</h1></div>
            <div style={{alignSelf: 'center', marginLeft: 'auto'}}>
            <h3 style={{float: 'left',width: '115px', height: '90px', alignContent: 'flex-end', textAlign: 'left'}}>Ditch the map & get the app!</h3>
            <img src={qrcode} alt="SVG QR Code" style={{width: '120px', height: '120px'}} />
              </div>
            </div>
        </div>
        </div>
    );
  };

  export default PDFTurnByTurnDirections;